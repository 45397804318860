import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { useInView } from "react-intersection-observer";

import { AiFillFacebook } from "@react-icons/all-files/ai/AiFillFacebook";
import { AiFillInstagram } from "@react-icons/all-files/ai/AiFillInstagram";
import { AiFillYoutube } from "@react-icons/all-files/ai/AiFillYoutube";
import { FiShare } from "@react-icons/all-files/fi/FiShare";

const smooth = "transition-all ease-in-out duration-300";
const hover = "transition-all ease-in-out duration-300 hover:scale-[105%]";

const LoadLogo = graphql`
  {
    allFile(filter: { name: { eq: "logo_white" }, extension: { eq: "png" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
    }
  }
`;
const Footer = () => {
  const data = useStaticQuery(LoadLogo);
  const image = getImage(data.allFile.nodes[0]);
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <div
      ref={ref}
      className="relative z-10 md:h-80 brightness-[95%] w-full bg-blue-810 flex flex-wrap md:flex-nowrap justify-center items-start md:items-center text-white shadow-xl shadow-stone-900/10 drop-shadow-md 2xl:rounded-t-md"
    >
      {/* logo */}
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView
            ? "opacity-100 translate-y-0 "
            : "opacity-0 translate-y-[200px]"
        } hidden md:flex flex-col justify-center items-center w-[35%] h-full`}
      >
        <Link to="/" className={`${hover} flex justify-center items-center`}>
          <GatsbyImage image={image} alt="logo" className={`w-28 lg:w-36`} />
        </Link>
        <div className="w-full flex justify-center items-center mt-6 space-x-8">
          <button
            className={`${hover} group flex flex-col justify-center items-center`}
          >
            <AiFillFacebook
              className={`${smooth} text-3xl mb-1 group-hover:text-blue-400`}
            />
            <div
              className={`${smooth} font-lufga200 group-hover:text-blue-400`}
            >
              Facebook
            </div>
          </button>
          <button
            className={`${hover} group flex flex-col justify-center items-center`}
          >
            <AiFillInstagram
              className={`${smooth} text-3xl mb-1 group-hover:text-pink-500`}
            />
            <div
              className={`${smooth} font-lufga200 group-hover:text-pink-500`}
            >
              Instargram
            </div>
          </button>
          <button
            className={`${hover} group flex flex-col justify-center items-center`}
          >
            <AiFillYoutube
              className={`${smooth} text-3xl mb-1 group-hover:text-red-500`}
            />
            <div className={`${smooth} font-lufga200 group-hover:text-red-500`}>
              Youtube
            </div>
          </button>
        </div>
        <div className="font-lufga200 mt-5 text-sm">
          © All rights reserved by Tamper, 2022
        </div>
      </div>
      {/* quick link */}
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView
            ? "opacity-100 translate-y-0 "
            : "opacity-0 translate-y-[200px]"
        } flex flex-col justify-start items-center w-[40%] md:w-[20%] h-full mt-8 md:mt-32`}
      >
        <div className="font-lufga300 underline underline-offset-8 mb-4">
          Quick Links
        </div>
        <Link
          to="/"
          className={`${smooth} hover:text-white hover:font-lufga300 font-lufga200 text-stone-200 my-1.5`}
        >
          Welcome
        </Link>
        <Link
          to="/menu/"
          className={`${smooth} hover:text-white hover:font-lufga300 font-lufga200 text-stone-200 my-1.5`}
        >
          Menu
        </Link>
        <Link
          to="/product/"
          className={`${smooth} hover:text-white hover:font-lufga300 font-lufga200 text-stone-200 my-1.5`}
        >
          Product
        </Link>
        <Link
          to="/story/"
          className={`${smooth} hover:text-white hover:font-lufga300 font-lufga200 text-stone-200 my-1.5`}
        >
          Story
        </Link>
      </div>
      {/* contact */}
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView
            ? "opacity-100 translate-y-0 "
            : "opacity-0 translate-y-[200px]"
        } flex flex-col justify-start items-start w-[40%] md:w-[18%] h-full ml-5 md:ml-0 mt-8 md:mt-32 md:mr-6`}
      >
        <div className="font-lufga300 underline underline-offset-8 mb-4">
          Contact
        </div>
        <Link
          to="/"
          className={`${smooth} hover:text-white hover:font-lufga300 font-lufga200 text-stone-200 my-1`}
        >
          T +8562012345678
        </Link>
        <Link
          to="/"
          className={`${smooth} hover:text-white hover:font-lufga300 font-lufga200 text-stone-200 my-1`}
        >
          example@mail.com
        </Link>
        <Link
          to="/"
          className={`${smooth} hover:text-white hover:font-lufga300 font-lufga200 text-stone-200 my-1 flex justify-center items-center`}
        >
          FB Messenger <FiShare className="ml-1.5" />
        </Link>
      </div>
      {/* address */}
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView
            ? "opacity-100 translate-y-0 "
            : "opacity-0 translate-y-[200px]"
        } flex flex-col justify-start items-start w-[40%] md:w-[20%] h-full mt-8 md:mt-32 mb-10 md:mb-0`}
      >
        <div className="font-lufga300 underline underline-offset-8 mb-4">
          Address
        </div>
        <Link
          to="/"
          className={`${smooth} hover:text-white hover:font-lufga300 font-lufga200 text-stone-200 my-1`}
        >
          48/1 Thep Krasatti Road, Talad Yai, Phuket, 83000, Thailand
        </Link>
      </div>
      {/* logo mb*/}
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView
            ? "opacity-100 translate-y-0 "
            : "opacity-0 translate-y-[200px]"
        } flex md:hidden flex-col justify-center items-center w-full md:w-[35%] h-full mb-10`}
      >
        <Link to="/" className={`${hover} flex justify-center items-center`}>
          <GatsbyImage image={image} alt="logo" className={`w-28 lg:w-36`} />
        </Link>
        <div className="w-full flex justify-center items-center mt-6 space-x-8">
          <button
            className={`${hover} group flex flex-col justify-center items-center`}
          >
            <AiFillFacebook
              className={`${smooth} text-3xl mb-1 group-hover:text-blue-400`}
            />
            <div
              className={`${smooth} font-lufga200 group-hover:text-blue-400`}
            >
              Facebook
            </div>
          </button>
          <button
            className={`${hover} group flex flex-col justify-center items-center`}
          >
            <AiFillInstagram
              className={`${smooth} text-3xl mb-1 group-hover:text-pink-500`}
            />
            <div
              className={`${smooth} font-lufga200 group-hover:text-pink-500`}
            >
              Instargram
            </div>
          </button>
          <button
            className={`${hover} group flex flex-col justify-center items-center`}
          >
            <AiFillYoutube
              className={`${smooth} text-3xl mb-1 group-hover:text-red-500`}
            />
            <div className={`${smooth} font-lufga200 group-hover:text-red-500`}>
              Youtube
            </div>
          </button>
        </div>
        <div className="font-lufga200 mt-5 text-sm">
          © All rights reserved by Tamper, 2022
        </div>
      </div>
    </div>
  );
};

export default Footer;
