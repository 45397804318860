import React, { useState, useEffect } from "react";
import { HiArrowCircleUp } from "@react-icons/all-files/hi/HiArrowCircleUp";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const ButtonUp = (props) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 150) {
      setVisible(true);
    } else if (scrolled <= 150) {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);

  return (
    <div className="">
      <AnchorLink to={`/${props.link}`}>
        <button
          aria-label="up"
          className={`transition-all duration-300 transform ${
            visible ? "bottom-8" : "-bottom-32"
          } focus:outline-none fixed right-6 lg:right-16 z-50 bg-white h-12 w-12 md:h-14 md:w-14 rounded-full shadow-lg text-xl md:text-2xl text-green-110 hover:text-blue-710 flex justify-center items-center`}
        >
          <HiArrowCircleUp />
        </button>
      </AnchorLink>
    </div>
  );
};

export default ButtonUp;
