import React, { useState } from "react";
import { Link } from "gatsby";
import { CgMenuGridO } from "@react-icons/all-files/cg/CgMenuGridO";
import { FiMinimize2 } from "@react-icons/all-files/fi/FiMinimize2";
import { IoCall } from "@react-icons/all-files/io5/IoCall";
import { IoCallOutline } from "@react-icons/all-files/io5/IoCallOutline";
import { TiLocation } from "@react-icons/all-files/ti/TiLocation";
import { IoLocationOutline } from "@react-icons/all-files/io5/IoLocationOutline";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useInView } from "react-intersection-observer";

const hover = "transition-all ease-in-out duration-300 hover:scale-[105%]";
const smooth = "transition-all ease-in-out duration-300";

const LoadLogo = graphql`
  {
    allFile(filter: { name: { eq: "logo_og" }, extension: { eq: "png" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
    }
  }
`;

const Navbar = (props) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
  });
  const data = useStaticQuery(LoadLogo);
  const image = getImage(data.allFile.nodes[0]);
  const [open, setOpen] = useState(false);

  return (
    <div
      ref={ref}
      className={`transition-all ease-in-out duration-[100ms] ${
        inView ? "opacity-100" : "opacity-0"
      } bg-white overflow-hidden mb-1.5 w-screen flex justify-center shadow-md 2xl:rounded-b-md`}
    >
      <div className="flex justify-center w-screen 2xl:w-new">
        {/* Logo */}
        <div
          className={`${hover} relative z-10 pl-4 lg:pl-0 flex justify-start lg:justify-center items-center w-1/2 lg:w-1/5 cursor-pointer`}
        >
          <Link to="/" className="flex justify-center items-center ">
            <GatsbyImage image={image} alt="logo" className={`w-28 lg:w-40`} />
          </Link>
        </div>

        {/* Links */}
        <div className="relative z-30 hidden lg:flex justify-center items-center w-4/5">
          <nav className="text-xl flex justify-end items-center space-x-5 font-lufga500 w-full h-full ">
            <Link
              to="/"
              className={`${hover} flex justify-center items-center py-2 w-[100px] hover:text-blue-710`}
              activeClassName={`text-blue-710 scale-[105%]`}
            >
              Welcome
            </Link>
            <Link
              to="/menu"
              className={`${hover} flex justify-center items-center py-2 w-[120px] hover:text-blue-710`}
              activeClassName={`text-blue-710 scale-[105%]`}
            >
              Menu
            </Link>
            <Link
              to="/product"
              className={`${hover} flex justify-center items-center py-2 w-[100px] hover:text-blue-710`}
              activeClassName="text-blue-710 scale-[105%]"
            >
              Product
            </Link>
            <Link
              to="/story"
              className={`${hover} flex justify-center items-center py-2 w-[100px] hover:text-blue-710`}
              activeClassName="text-blue-710 scale-[105%]"
            >
              Story
            </Link>
          </nav>
          {/* line */}
          <div className="w-[2.5px] h-[40px] bg-stone-200 mx-5"></div>
          {/* line */}
          <div className="flex justify-center items-center mr-5">
            <AnchorLink
              to={`/${props.link}`}
              className={`${hover} invisible bg-stone-800 hover:bg-blue-710 hover:shadow-lg rounded-full h-16 w-16 mx-5 drop-shadow-md shadow-md grid place-content-center`}
            >
              <IoCall className="text-white text-[1.8rem]" />
            </AnchorLink>
            <button
              className={`${hover} bg-stone-800 hover:bg-blue-710 hover:shadow-lg rounded-full h-16 w-16 mx-5 drop-shadow-md shadow-md grid place-content-center`}
            >
              <TiLocation className="text-white text-[2rem]" />
            </button>
          </div>
        </div>

        {/* Mobile Menu Close */}
        <div className="relative z-20 pr-3 md:pr-8 flex lg:hidden justify-end items-center w-1/2 ">
          <AnchorLink
            to={`/${props.link}`}
            className={`${hover} p-2.5 text-3xl text-stone-900 hover:text-blue-710`}
          >
            <IoCallOutline />
          </AnchorLink>
          <button
            className={`${hover} p-2.5 text-3xl text-stone-900 hover:text-blue-710 mr-2`}
          >
            <IoLocationOutline />
          </button>
          {/* line */}
          <div className="w-[2px] h-[25px] bg-slate-300" />
          {/* line */}
          <button
            onClick={() => setOpen((prevOpen) => !prevOpen)}
            className={`${hover} m-4 text-3xl text-stone-900 hover:text-blue-710`}
          >
            <CgMenuGridO />
          </button>
        </div>

        {/* Mobile Menu Overlay */}
        <button
          onClick={() => setOpen((prevOpen) => !prevOpen)}
          aria-label="overlay"
          className={`cursor-e-resize  ${
            open === true
              ? "z-30 bg-gray-800 visible opacity-50 absolute h-full w-screen transition-all ease-in-out duration-300"
              : "z-30 bg-gray-800 invisible opacity-0 absolute h-full w-screen transition-all ease-in-out duration-300 "
          }`}
        ></button>

        {/* Mobile Menu Open */}
        <div className="overflow-hidden h-full absolute w-[85%] sm:w-3/5 right-0">
          <div
            className={`transform ${
              open === true
                ? "z-40 relative translate-x-0 transition-all ease-in-out duration-300"
                : "z-40 relative translate-x-full transition-all ease-in-out duration-300 "
            } h-full bg-gray-900 flex flex-col justify-start items-center pt-8 `}
          >
            <div className="flex justify-end w-full pr-8">
              {/* <ScrollLock isActive={open} /> */}
              <button
                onClick={() => setOpen((prevOpen) => !prevOpen)}
                className={`${smooth} p-3 shadow-lg drop-shadow-md rounded-full bg-gradient-to-t from-slate-900 to-slate-800 text-2xl text-white hover:text-blue-510 flex justify-center items-end`}
              >
                <span className="text-base font-lufga200 mr-3">(close)</span>
                <FiMinimize2 className="" />
              </button>
            </div>
            <div className="flex flex-col justify-start items-center w-full py-8 space-y-1.5 text-lg font-lufga400  font-semibold">
              <Link
                className={`${smooth} flex justify-start w-3/4 text-white hover:text-blue-510 hover:bg-blue-710 hover:bg-opacity-20 py-3 pl-4 rounded-lg`}
                to="/"
              >
                Welcome
              </Link>
              <Link
                className={`${smooth} flex justify-start w-3/4 text-white hover:text-blue-510 hover:bg-blue-710 hover:bg-opacity-20 py-3 pl-4 rounded-lg`}
                to="/menu"
              >
                Menu
              </Link>
              <Link
                className={`${smooth} flex justify-start w-3/4 text-white hover:text-blue-510 hover:bg-blue-710 hover:bg-opacity-20 py-3 pl-4 rounded-lg`}
                to="/product"
              >
                Product
              </Link>
              <Link
                className={`${smooth} flex justify-start w-3/4 text-white hover:text-blue-510 hover:bg-blue-710 hover:bg-opacity-20 py-3 pl-4 rounded-lg`}
                to="/story"
              >
                Story
              </Link>
              {/* <div className="border-t-2 border-stone-300 w-3/4 pt-4 font-normal text-xs flex justify-center font-lufga400 ">
                © All rights reserved to Econews, 2022
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
