import React from "react";
import { VscCallOutgoing } from "@react-icons/all-files/vsc/VscCallOutgoing";
import { IoGlobeOutline } from "@react-icons/all-files/io5/IoGlobeOutline";
import { AiFillHeart } from "@react-icons/all-files/ai/AiFillHeart";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

const Img01 = "../images/googlePin.png";

const smooth = "transition-all ease-in-out duration-300";
const hover = "transition-all ease-in-out duration-300 hover:scale-[105%]";

const Contact = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <div
      ref={ref}
      className="h-auto w-full flex flex-col justify-center items-center"
    >
      <div id="contact" />
      {/* contact into */}
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView
            ? "opacity-100 translate-y-0 "
            : "opacity-0 translate-y-[200px]"
        } w-[80%] sm:w-[60%] md:w-[96%] lg:w-[72%] mt-16 h-auto bg-white flex flex-col justify-center items-center rounded-xl shadow-xl shadow-stone-900/10 drop-shadow-md`}
      >
        <div
          className={`h-[40%] pt-10 pb-6 w-full px-6 md:px-10 lg:px-10 xl:px-12 flex justify-center md:justify-start items-center font-lufga400 text-2xl italic`}
        >
          Contact Info:
        </div>
        <div className="h-[60%] pb-8 w-full px-2 md:px-4 lg:px-4 xl:px-8 flex flex-col md:flex-row justify-between items-center">
          <div className="mx-4 mb-4 md:mb-0 w-[240px] md:w-[260px] bg-stone-200/60 rounded-full flex justify-start items-center">
            <div className="w-[15%] m-1.5">
              <button
                className={`${hover} hover:bg-blue-710 w-12 h-12 bg-stone-900 grid place-content-center rounded-full`}
              >
                <VscCallOutgoing className="text-white text-2xl" />
              </button>
            </div>
            <button
              className={`${smooth} hover:text-blue-710  w-[85%] h-full font-lufga300 grid place-content-center text-lg md:text-xl`}
            >
              +856201234567
            </button>
          </div>
          <div className="mx-4 mb-4 md:mb-0 w-[240px] md:w-[260px] bg-stone-200/60 rounded-full flex justify-start items-center">
            <div className="w-[15%] m-1.5">
              <button
                className={`${hover} hover:bg-blue-710 w-12 h-12 bg-stone-900 grid place-content-center rounded-full`}
              >
                <IoGlobeOutline className="text-white text-[1.6rem]" />
              </button>
            </div>
            <button
              className={`${smooth} hover:text-blue-710  w-[85%] h-full font-lufga300 grid place-content-center text-lg md:text-xl`}
            >
              @fb/coffeeshop
            </button>
          </div>
          <div className="mx-4 w-[240px] md:w-[260px] bg-stone-200/60 rounded-full flex justify-start items-center">
            <div className="w-[15%] m-1.5">
              <button
                className={`${hover} hover:bg-blue-710 w-12 h-12 bg-stone-900 grid place-content-center rounded-full`}
              >
                <StaticImage
                  src={Img01}
                  alt="pin"
                  layout="constrained"
                  placeholder="tracedSVG"
                  className="object-contain w-5 "
                />
              </button>
            </div>
            <button
              className={`${smooth} text-green-700 hover:text-green-600 w-[85%] h-full font-lufga300 grid place-content-center text-xl`}
            >
              Google Map
            </button>
          </div>
        </div>
      </div>
      {/* contact form */}
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView
            ? "opacity-100 translate-y-0 "
            : "opacity-0 translate-y-[200px]"
        } w-80% md:w-[75%] h-auto flex flex-col justify-center items-center`}
      >
        <div className="flex justify-center items-center font-runegifter text-[1.6rem] md:text-4xl text-yellow-710 brightness-110 mt-10">
          We love to talk to you
          <AiFillHeart className="ml-4 text-[1.5rem] md:text-3xl" />
        </div>
        <div className="text-center mt-3 md:mt-5 w-[60%] font-lufga300">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
          vehicula, urna vel consectetur egestas, risus orci eleifend nulla, eu
          molestie risus enim vel quam. Nulla rutrum elit id nisl aliquet.
        </div>
        <div className="mt-6 md:mt-10 w-[90%] md:w-[80%] h-20 bg-white rounded-xl shadow-xl mb-16 flex justify-center items-center">
          <form
            // onSubmit={onSubmit}
            className="flex md justify-center items-center w-full h-full pl-3 md:pl-5 pr-3"
          >
            <input
              type="text"
              name="from_name"
              placeholder="Drop your email ..."
              // value={toSend.from_name}
              // onChange={handleChange}
              className="p-1 bg-stone-200/60 text-stone-900 border-b border-white w-[72%] md:w-[75%] font-lufga300 px-3 md:px-5 py-2 rounded-lg"
            />
            <div className="w-[2px] h-[30px] bg-stone-300 mx-2 md:mx-4" />
            <button
              className={`${hover} hover:bg-blue-710 w-[28%] md:w-[25%] h-auto py-3 px-4 flex justify-center items-center border rounded-xl text-base sm:text-lg md:text-xl italic text-white bg-stone-900 font-lufga300`}
            >
              Subsribe
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
